<template>
  <div class="content">
    <div class="page">
      <div class="img_demo">
        <img src="" alt="" />
        <div class="right">
          <h2>城市综合管理</h2>
          <div class="message">
            以城市综合管理为对象，构建科学的预警监控体系，整合城市规划、交通、治安、城管、环境、气象等方面的数据资源，建立可视化和智能化的大数据应用分析模型，通过对海量数据的智能分类、整理和分析，使城市管理者能够更准确地预测可能出现的情况，及时调度资源。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 387px);
  .page {
    margin-top: 50px;
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > img {
      display: inline-block;
      width: 50%;
      background: url("../../../../assets/image/ruralImg/img5.png") no-repeat;
      background-size: 100% 100%;
    }
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
}
</style>